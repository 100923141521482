import WidgetWrapper from "../../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import GenericChartWidget from "../../../../../climateui/components/Widgets/SeasonalWidgets/GenericChartWidget"
import { gql } from "graphql-request"
import IWidgetDefaultProps from "./WidgetDefaultProps"
import { useTranslate } from "@tolgee/react"

function VariableChartWidget(props: IWidgetDefaultProps) {
    const { id, onLoad, onMount, dashboardFilters, selectors, actions } = props
    const { t } = useTranslate()
    return (
        <WidgetWrapper
            id={id}
            onLoad={onLoad}
            onMount={onMount}
            selectedFilters={[
                "unit_system",
                "end_date_gte",
                "start_date_lte",
                "risk_settings_ids",
                "granularity",
                "config",
                "location_ids",
            ]}
            component={GenericChartWidget}
            query={gql`
                query (
                    $location_ids: [String]
                    $config: [WeatherConfig]!
                    $granularity: String
                    $risk_settings_ids: [String]
                    $start_date_lte: String
                    $end_date_gte: String
                    $unit_system: String
                ) {
                    locations(filter: { location_ids: $location_ids }) {
                        results {
                            name
                            latitude
                            longitude
                            weather(
                                filter: {
                                    config: $config
                                    granularity: $granularity
                                    unit_system: $unit_system
                                }
                            ) {
                                data
                            }
                            varieties {
                                results {
                                    name
                                    asset {
                                        name
                                    }
                                }
                            }
                            labels {
                                name
                            }
                        }
                    }
                    alerts(
                        filter: {
                            risk_settings_ids: $risk_settings_ids
                            location_ids: $location_ids
                            start_date_lte: $start_date_lte
                            end_date_gte: $end_date_gte
                        }
                    ) {
                        results {
                            start_date
                            end_date
                            duration
                            avg_risk_value
                            time_resolution
                            category
                            expected_magnitude
                            expected_magnitude_list
                            expected_percentile
                            frequency
                            last_occurrence
                            trigger_threshold_list
                            processing_run {
                                risk_setting_id
                                location {
                                    name
                                    id
                                }
                                risk_profile {
                                    id
                                    name
                                    probability
                                    type
                                    hazard_profiles {
                                        results {
                                            id
                                            window
                                            threshold(unit_system: $unit_system)
                                            type
                                            logical_op
                                            conditional
                                            hazard_variable {
                                                dashboard_variable
                                                readable_name
                                                units(unit_system: $unit_system)
                                                units_metric
                                                units_imperial
                                            }
                                        }
                                    }
                                    varieties {
                                        results {
                                            name
                                            asset {
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            selectors={{
                ...selectors,
                $items: "locations.results[0].weather.data",
                $alerts: "alerts.results",
                $varieties: "locations.results[0].varieties.results",
                $labels: "locations.results[0].labels",
                $location: "locations.results[0]",
                errorMessage: t("errorLoadingData"),
                reloadMessage: t("clickReload"),
                noResultsMessage: t("noResultCurrentFilters"),
                tryAnotherFilterMessage: t("tryAnotherFilter"),
            }}
            actions={{
                translationFn: t,
                ...actions,
            }}
            filters={dashboardFilters}
        />
    )
}

export default VariableChartWidget
