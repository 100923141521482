import { StackedRiskIcon } from "../../icons/riskIcons"
import DefaultRiskIcon from "../../icons/riskIcons/DefaultRiskIcon"
import DroughtIcon from "../../icons/variableIcons/DroughtIcon"
import HeatIcon from "../../icons/variableIcons/HeatIcon"
import LowTemperatureIcon from "../../icons/variableIcons/LowTemperatureIcon"
import MaxTemperatureIcon from "../../icons/variableIcons/MaxTemperatureIcon"
import MeanHumidityIcon from "../../icons/variableIcons/MeanHumidityIcon"
import MeanPrecipitationIcon from "../../icons/variableIcons/MeanPrecipitationIcon"
import MeanSoilMoistureIcon from "../../icons/variableIcons/MeanSoilMoistureIcon"
import MeanSoilTemperatureIcon from "../../icons/variableIcons/MeanSoilTemperatureIcon"
import MeanTemperatureIcon from "../../icons/variableIcons/MeanTemperatureIcon"
import MeanWindSpeedIcon from "../../icons/variableIcons/MeanWindSpeedIcon"
import MinTemperatureIcon from "../../icons/variableIcons/MinTemperatureIcon"
import SumPrecipitationIcon from "../../icons/variableIcons/SumPrecipitation"
import {
    EvapotranspirationIcon,
    MaxWindSpeedIcon,
    SolarRadiationIcon,
} from "../../icons/weatherVariableIcons"

function getVariableIcon(value: string) {
    switch (value) {
        case "precipitation":
        case "tp":
            return <MeanPrecipitationIcon />
        case "precipitation_sum":
        case "tp_sum":
            return <SumPrecipitationIcon />
        case "temp_mean":
        case "t2m_mean":
            return <MeanTemperatureIcon />
        case "temp_min":
        case "t2m_min":
            return <MinTemperatureIcon />
        case "temp_max":
        case "t2m_max":
            return <MaxTemperatureIcon />
        case "wind_speed":
        case "ws":
            return <MeanWindSpeedIcon />
        case "max_wind_speed":
            return <MaxWindSpeedIcon />
        case "mean_soil":
        case "stl1":
        case "soil_temperature":
            return <MeanSoilTemperatureIcon />
        case "sm":
        case "swvl1":
            return <MeanSoilMoistureIcon />
        case "e":
        case "evapotranspiration":
            return <EvapotranspirationIcon />
        case "humidity":
            return <MeanHumidityIcon />
        case "heat":
            return <HeatIcon />
        case "drought":
            return <DroughtIcon />
        case "solar_radiation":
        case "ssrd":
        case "rad":
            return <SolarRadiationIcon />
        case "cold":
            return <LowTemperatureIcon />
        case "stacked":
            return <StackedRiskIcon />
        default:
            return <DefaultRiskIcon />
    }
}

export default getVariableIcon
