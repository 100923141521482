import { DefaultParamType, TFnType, useTranslate } from "@tolgee/react"
import { IMapPin } from "../../../../climateui/components/Map/utils"
import {
    IAlert,
    IInsightsLocation,
    IMapAlertPopUp,
    IRiskProfile,
    ITrigger,
    IVariety,
} from "../../../../types"
import { RiskIconHandler } from "../../../../climateui/icons/riskIcons"
import {
    CancelIcon,
    LinkIcon,
    ThreeDotsIcon,
} from "../../../../climateui/icons"
import mapboxgl from "../../../../climateui/components/Map/mapboxgl"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { createRoot } from "react-dom/client"
import { useRef, useEffect } from "react"
import { useAssets } from "../../../../providers"
import { formatDateRange, TimeResolutionValues } from "../utils"

const ALERT_CATEGORIES = ["warning", "watch"] as const
export type alertCategoriesEnum = (typeof ALERT_CATEGORIES)[number]
const alertColorPalettes = {
    watch: {
        backgroundColor: "#FFF8E5",
        fillColor: "#CC9300",
    },
    warning: {
        backgroundColor: "#FCE9EB",
        fillColor: "#BF1728",
    },
}

const formatter = Intl.NumberFormat(undefined, {
    style: "percent",
    maximumFractionDigits: 0,
})

const RiskConditionRow = ({
    alertCategory = "warning",
    riskProfile,
    alert,
}: {
    alertCategory: alertCategoriesEnum
    riskProfile: IRiskProfile
    alert?: IMapAlertPopUp
    
}) => {
    const alertColorStyle = alertColorPalettes[alertCategory]
    
    if(!alert) return null
    
    const time_resolution = (["daily", "weekly", "monthly"].includes(
        alert.time_resolution
    )
        ? alert.time_resolution
        : "daily") as TimeResolutionValues

    
    return (
        <div className="flex flex-row w-full gap-2">
            <div
                className="p-[7px] shrink-0 grow-0 w-9 h-9 rounded-sm"
                style={{
                    fill: alertColorStyle.fillColor,
                    backgroundColor: alertColorStyle.backgroundColor,
                }}>
                <RiskIconHandler hazardProfiles={riskProfile.hazard_profiles} />
            </div>
            <div className="flex-wrap body-sm">
                <div className="text-gray-90 font-medium">{riskProfile.name}</div>
                <div className="text-gray-90">{formatDateRange(time_resolution, alert.start_date, alert.end_date)}</div>
            </div>
        </div>
    )
}

const AlertsPopUp = (props: {
    t: TFnType<DefaultParamType, string> // Tolgee Translation
    navigate: NavigateFunction
    alertCategory: alertCategoriesEnum
    location: IInsightsLocation
    alerts: IMapAlertPopUp[]
    riskProfiles: Record<string, IRiskProfile>
    varietiesOptions: Record<string, IVariety>
}) => {
    const MAX_ALERTS = 3
    const {
        location,
        riskProfiles,
        alerts,
        t,
        alertCategory,
    } = props
    
    const surplus = (alerts || []).length - MAX_ALERTS
    return (
        <div className="w-64 -m-1">
            <div className="flex flex-col">
                <div className="flex font-medium text-left text-gray-90 body-md">
                    {location.name}
                </div>
                {(alerts || []).length === 0 && (
                    <div className="flex flex-wrap body-sm">
                        <span className="text-green">
                            {t(
                                "noAlertsForCurrentSelection",
                                "No alerts for the current filters selection"
                            )}
                        </span>
                        <span className="text-gray-60">
                            {t(
                                "clickPinToLocationDashboard",
                                "Click pin to go to Location dashboard"
                            )}
                        </span>
                    </div>
                )}
            </div>
            <div>
                <div className="flex flex-col my-3 w-fit gap-y-2">
                        {
                            (alerts || []).slice(0, MAX_ALERTS).map((alert, index) => (  
                                <div key={alert.id}>
                                    <RiskConditionRow
                                        alertCategory={alertCategory}
                                        alert={alert}
                                        riskProfile={riskProfiles[alert.risk_profile_id]}
                                    />
                                </div>
                            ))
                        }
                </div>
                {surplus > 0 && (
                    <div className="mb-2 text-xs text-center text-gray-60 gap-2">
                        {surplus} {t("more", "more")}
                    </div>
                )}
            </div>
        </div>
    )
}
const RightSidePopup = (props: {
    location: IInsightsLocation
    t: TFnType<DefaultParamType, string>
    navigate: NavigateFunction
    riskProfiles: Record<string, IRiskProfile>
    onClose: () => void
}) => {
    const MAX_RISKS = 4
    const {
        location,
        riskProfiles: _riskProfiles,
        t,
        navigate,
        onClose,
    } = props
    const riskProfiles = Object.values(_riskProfiles)
    const surplus = riskProfiles.length - MAX_RISKS
    const popupRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node)
            ) {
                onClose()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [onClose])

    return (
        <div
            ref={popupRef}
            className="fixed top-0 right-0 z-50 w-64 h-auto p-4 mt-16 mr-4 text-gray-800 bg-white rounded-lg shadow-lg">
            <button
                onClick={onClose}
                className="absolute top-2.6 left-56 w-4 h-4 p-0 border-none bg-transparent">
                <CancelIcon className="w-5.5 h-5.5" />
            </button>
            <div className="w-48">
                <div className="flex flex-col">
                    <button
                        onClick={() =>
                            navigate(
                                `/seasonal/dashboards/location?locId=${location.id}`
                            )
                        }
                        className="flex font-bold text-left text-gray-90">
                        {location.name}
                        <div className="w-[25px] h-[25px] ml-1">
                            <LinkIcon />
                        </div>
                    </button>
                    <span className="text-xs text-gray-60">
                        {riskProfiles.length}{" "}
                        {t("alertsTriggered", "Alerts triggered")}
                    </span>
                </div>
                <div>
                    <div className="flex flex-col my-3 gap-1">
                        {riskProfiles.slice(0, MAX_RISKS).map((riskProfile) => (
                            <div key={riskProfile.id}>
                                <RiskConditionRow
                                    alertCategory="warning" //We will change this once we merge new right side panel changes/addition
                                    alert={{} as IMapAlertPopUp}
                                    riskProfile={riskProfile}
                                />
                            </div>
                        ))}
                    </div>
                    {surplus > 0 && (
                        <div className="flex flex-row items-center text-xs text-gray-60 gap-2">
                            <div className="w-5 h-5 rotate-90 fill-gray-60">
                                <ThreeDotsIcon />
                            </div>
                            {surplus} {t("moreAlerts", "more alert(s)")}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const useRightSidePopup = () => {
    // const { t } = useTranslate()
    // const navigate = useNavigate()

    return {
        getRightSidePopup: (pin: IMapPin) => {
            const popupDOMContainer = document.createElement("div")
            const onClose = () => {
                if (document.body.contains(popupDOMContainer)) {
                    document.body.removeChild(popupDOMContainer)
                }
            }
            //Commented while working in this RightSidePopup

            // document.body.appendChild(popupDOMContainer);
            // const popupRoot = createRoot(popupDOMContainer);
            // popupRoot.render(
            //     <RightSidePopup
            //         location={pin.data.location}
            //         riskProfiles={pin.data.riskProfiles}
            //         navigate={navigate}
            //         t={t}
            //         onClose={onClose}
            //     />
            // );
            return onClose
        },
    }
}

export const useAlertsMarkerPopup = () => {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { varieties } = useAssets()
    return {
        getAlertsMarkerPopup: (pin: IMapPin) => {
            // we can't use the static render anymore for this since
            // there's need for listening events inside the AlertsPopup comp
            const popupDOMContainer = document.createElement("div")
            const popupRoot = createRoot(popupDOMContainer)

            popupRoot.render(
                <AlertsPopUp
                    location={pin.data.location}
                    alertCategory={pin.data.category}
                    alerts={pin.data.alerts}
                    riskProfiles={pin.data.riskProfiles}
                    varietiesOptions={varieties ?? {}}
                    navigate={navigate}
                    t={t}
                />
            )

            return new mapboxgl.Popup({
                className: "font-roboto z-full",
                offset: 0,
                closeButton: false,
            }).setDOMContent(popupDOMContainer)
        },
    }
}
