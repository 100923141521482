import React from "react"
import {
    IBooleanDictionary,
    ILabel,
    IPrimitivesDictionary,
    ICoordinates,
} from "./climateui/types"

export interface IPermission {
    id: string
    created_at: string
    updated_at: string
    name: string
}

export interface IRole {
    id: string
    name: string
    description: string
    permissions: IPermission[]
}

export interface IDecodedToken {
    fresh: boolean
    iat: number
    jti: string
    type: string
    sub: string
    nbf: number
    exp: number
    roles: string[]
    uber_admin: boolean
    account_id: string
    status?: string
}

export interface IAccount {
    id: string | number
    name: string
    parent_id: string | number
    children?: IAccount[]
    logo?: string
    logo_url?: string
    status: string
    updated_at?: string
    created_at?: string
    currency: string
    is_onboarded?: boolean
}

export interface IAccountConfig {
    account_id: string
    config_type: string
    config_value: string
    created_at: string
    id: string
    updated_at: Date | string
}

export interface IAccountUserRelationship {
    account_id: string | number
    role: {
        id: string | number
        description: string
        name: string
    }
    role_id: string
}
export interface IFullAccountUserRelationship {
    account_id: string
    role_id: string
    created_at: string
    updated_at: string
    last_used: string
}

export interface IRegion {
    id?: string
    name: string
    full_name: string
    iso: string
    resolution: number
    parent_id?: string
    children?: IRegion[]
    center?: number[]
    created_at?: Date | string
    updated_at?: Date | string
}

export interface IHazardVariable {
    id: string
    created_at: string
    updated_at: string
    readable_name: string
    readable_variable: string
    zarr_variable: string
    dashboard_variable: string
    units_imperial: string
    units_metric: string
    units?: string
    aggregation: string
}
export interface IImpactFunction {
    id: string
    created_at: string
    updated_at: string
    readable_name: string
    backend_name: string
    type: string
    aggregation: string
    icon: string
    image: string
}
export interface IHazardProfile {
    id: string
    hazard_variable: IHazardVariable
    conditional: string
    type: string
    threshold: number
    window: number
    risk_profile_id: string
    created_at: string
    updated_at: string
    logical_op?: string
}
export interface HazardProfileInput {
    hazard_variable_id: string
    conditional: string
    type: string
    threshold?: number
    window?: number
    logical_op: string
    aggregation: string
}
export const hazardProfileToInput = (
    hazardProfile: IHazardProfile,
    logical_op: string
): HazardProfileInput => {
    return {
        hazard_variable_id: hazardProfile.hazard_variable.id,
        conditional: hazardProfile.conditional,
        type: hazardProfile.type.toLowerCase(),
        threshold: hazardProfile.threshold,
        window: hazardProfile.window,
        logical_op: logical_op.toUpperCase(),
        aggregation: hazardProfile.hazard_variable.aggregation,
    }
}
export interface IImpactProfile {
    id: string
    impact_function: IImpactFunction
    risk_profile_id: string
    created_at: string
    updated_at: string
    impact_function_id?: string
    initial_impact: number
    marginal_impact: number
    max_impact: number
}
export interface ImpactProfileInput {
    impact_function_id: string
    initial_impact: number
    marginal_impact: number
    max_impact: number
}
export const impactProfileToInput = (
    impactProfile: IImpactProfile
): ImpactProfileInput => {
    return {
        impact_function_id: impactProfile.impact_function.id,
        initial_impact: impactProfile.initial_impact,
        marginal_impact: impactProfile.marginal_impact,
        max_impact: impactProfile.max_impact,
    }
}
export interface IAccountAsset {
    id: string
    account_id: string
    asset_id: string
    is_setup: boolean
}
export interface IAsset {
    created_at: string
    updated_at: string
    id: string
    name: string
    description: string
    industry_id: string
    meta: IPrimitivesDictionary
}
export interface AssetInput {
    name: string
    description?: string
    meta?: IPrimitivesDictionary
}

export interface IVarietyStageRiskProfile {
    id: string
    stage_id: string
    risk_profile_id: string
    risk_setting_ids?: string[]
}

export interface IVarietyStage {
    id: string
    days_from_start: number
    duration: number
    name: string
    stage_risk_profiles: IVarietyStageRiskProfile[]
    variety_id: string
    color?: string
}

export const VARIETY_EMPTY_INITIAL_DATE = "00-00"

export interface IVariety {
    id: string
    created_at: string
    updated_at: string
    name: string
    description: string
    account_id: string
    asset: IAsset
    asset_id: string
    is_default: boolean
    initial_date?: string
    full_name?: string

    stages?: IVarietyStage[]
    has_timeline?: boolean
}

export interface ILocationEssentials {
    id?: string // edit mode
    latitude: number
    longitude: number
    name?: string
    labels: string[] | ILabel[]
    varieties: string[] | IVariety[]
    region?: IRegion
}

export interface IInsightsLocation {
    id?: string
    name: string
    external_id: string
    account_id: string
    latitude: number
    longitude: number
    region?: IRegion
    regions?: IRegion[]
    timezone: string
    labels: string[] | ILabel[]
    varieties: IVariety[] 
    assets?: IAsset[]
    product_lines?: string[]
    // active?: boolean
    // updated_at?: string
    // created_at?: string
}
export interface InsightsLocationOnboarding {
    account_id: string
    external_id: string
    timezone: string
    name: string
    latitude: number
    longitude: number
    labels: string[]
    varieties: []
    assets?: IAsset[]
}
export interface VarietyMetadataInput {
    key: string
    value?: string
}
export interface VarietyInput {
    asset_id: string
    account_id: string
    name: string
    description?: string
    initial_date?: string
    is_default?: boolean
    variety_metadata: VarietyMetadataInput[]
}
export interface IVarietyAttribute {
    name: string
    unit: string
}
export interface IVarietyMetadata {
    id: string
    varieties: IVariety[]
    key: string
    value: string
    unit: string
    created_at: string
    updated_at: string
}
export const varietyMetadataToInput = ({
    key,
    value,
}: IVarietyMetadata) => {
    return {
        key,
        value,
    }
}
export interface IVariety {
    variety_metadata: IVarietyMetadata[]
}

export interface IRiskProfileService {
    account_id: string
    created_at: string
    hazard_profiles: IHazardProfile[]
    id: string
    impact_profile: IImpactProfile[]
    labels: ILabel[]
    name: string
    probability: number
    status: string
    updated_at: string
    varieties: string[]
}
export interface IRiskProfile {
    id: string
    account_id: string
    type: string
    name: string
    hazard_profiles: IHazardProfile[]
    impact_profile: IImpactProfile
    probability: number
    frequency?: number
    created_at: string
    updated_at: string
    labels: ILabel[]
    varieties: IVariety[]
    alerts_count?: number
    active_risk_settings?: {
        info?: {
            count?: number
        }
        results?: {
            location_id?: string
        }[]
    }
    risk_settings?: {
        info?: {
            count?: number
        }
    }
    stages?: {
        id: string
        name: string
        color: string
        duration: number
        days_from_start: number
        variety_id: string
    }[]
}

export interface IVarietyStageRiskProfile {
    risk_profile: IRiskProfile
}

export interface RiskProfileInput {
    account_id: string
    name: string
    probability: number
    hazard_profiles: HazardProfileInput[]
    impact_profile: ImpactProfileInput
    labels: string[]
    varieties: string[]
    type?: string
}

export const riskProfileToInput = (
    riskProfile: IRiskProfile
): RiskProfileInput => {
    return {
        account_id: riskProfile.account_id,
        name: riskProfile.name,
        probability: riskProfile.probability,
        hazard_profiles: riskProfile.hazard_profiles.map((hazardProfile, idx) =>
            // TODO: Update to support OR stacked alerts
            hazardProfileToInput(hazardProfile, idx === 0 ? "START" : "AND")
        ),
        impact_profile: impactProfileToInput(riskProfile.impact_profile),
        labels:
            riskProfile.labels?.map(
                (label: ILabel) => label.id ?? ""
            ) ?? [],
        varieties: riskProfile.varieties.map(({ id }) => id),
        type: riskProfile.type,
    }
}

export interface IWhiteLabelPlatform {
    id?: string
    name?: string
    primary_color?: string
    top_nav_color?: string
    welcome_string?: string
    status: string
    accounts?: string[]
    domain?: string
    logo?: string | File
    favicon?: string | File
    wallpaper?: string | File
    header?: string | File
    footer?: string | File
    created_at?: string
    updated_at?: string
}

// In the backend, this is called a Risk Setting.
interface IBaseAlertSetting {
    id: string
    location_id: string
    risk_profile_id: string
    product_id: string
    status: string
    lastTriggered?: string
    location?: IInsightsLocation
}
export interface IAlertSetting extends IBaseAlertSetting {
    created_at: string
    updated_at: string
    start_date: string
    end_date: string
}
export interface IAugmentedAlertSetting
    extends IBaseAlertSetting {
    dates: Record<string, [string, string]>
    createdAtDict: Record<string, string>
    updatedAtDict: Record<string, string>
}
export const toIAlertSettings = ({
    location_id,
    risk_profile_id,
    product_id,
    status,
    createdAtDict,
    updatedAtDict,
    dates,
}: IAugmentedAlertSetting): IAlertSetting[] => {
    return Object.entries(dates).map(([id, [start_date, end_date]]) => ({
        id,
        start_date,
        end_date,
        location_id,
        risk_profile_id,
        product_id,
        status,
        created_at: createdAtDict[id],
        updated_at: updatedAtDict[id],
    }))
}
export const collapseAugmentedAlert = (
    augmentedAlert: IAugmentedAlertSetting
): [IAugmentedAlertSetting, string[]] => {
    const alertsIDs = Object.keys(augmentedAlert.dates)
    if (alertsIDs.length === 1) return [augmentedAlert, []]
    const mainAlertID = alertsIDs[0]

    // Collapse dates
    augmentedAlert.dates = {
        [mainAlertID]: augmentedAlert.dates[mainAlertID],
    }

    // Collapse created at
    augmentedAlert.createdAtDict = {
        [mainAlertID]: augmentedAlert.createdAtDict[mainAlertID],
    }

    // Collapse updated at
    augmentedAlert.updatedAtDict = {
        [mainAlertID]: augmentedAlert.updatedAtDict[mainAlertID],
    }

    return [augmentedAlert, alertsIDs.slice(1)]
}
export interface AlertSettingInput {
    id?: string
    risk_profile_id: string
    location_id: string
    product_id: string
    status: string
    start_date: string
    end_date: string
}
export interface IRiskProfileTemplate {
    id: string
    created_at: string
    updated_at: string
    signed_url: string
    name: string
    storage_path: string
    asset_id: string
}
export interface IProcessingRun {
    id: string
    risk_setting_id: string
    location_id: string
    account_id: string
    risk_id: string
    valid_until: string
    ran_at: string
}
export interface IAlert {
    id: string
    created_at: string
    updated_at: string
    start_date: string
    end_date: string
    duration: number
    min_risk_value: number
    max_risk_value: number
    avg_risk_value: number
    time_resolution: string
    category: string
    expected_magnitude: number
    expected_magnitude_list: number[]
    trigger_threshold_list: number[]
    expected_percentile: number
    frequency: number
    last_occurrence: string
}

export interface IMapAlertPopUp {
    id: string,
    time_resolution: string,
    start_date: string,
    end_date: string,
    risk_profile_id: string
}

export interface ITrigger {
    processing_run: IProcessingRun
    alert: IAlert
}
export interface IFullTrigger extends ITrigger {
    location: IInsightsLocation
    risk_profile: IRiskProfile
}

export interface IGroupedAlerts {
    riskProfile: IRiskProfile
    location: IInsightsLocation | ILocationEssentials
    alerts: ITrigger[]
}

export type AlertDetailItem = {
    alert: IAlert
    location: {
        name: string
        id: string
        varieties: string[]
    }
    riskProfile: IRiskProfile
}

export interface ITimeSeries {
    data: number[]
    time: (string | Date)[] // dates
    duration?: number[]
}

export interface IPlannedRisk {
    id?: string
    end_date?: Date
    start_date?: Date
    duration?: number
    risk_profile_id?: string
    name?: string
    hazard_profiles?: IHazardProfile[]

    observed_impact?: number | null
    expected_impact?: number | null
    observed_impact_time_series?: ITimeSeries | null
    expected_impact_time_series?: ITimeSeries | null

    frontend_id?: string // FE id for logic
    strategyId?: string | number // FE id for UI
    location_name?: string // To show in PlannedRisk hover
    action?: string // for PUT
    type?: string // action type [stages | risks]

    stage_id?: string
}

export interface IStage {
    id?: string
    end_date?: Date
    start_date?: Date
    color?: string
    name?: string

    duration?: number
    deleteStage?: () => void
    index?: number
    strategyId?: string | number
    action?: string
    type?: string
    planned_risks?: IPlannedRisk[]
}

export interface IStrategy {
    id?: string
    asset_id?: string
    asset_variety_id?: string
    location_id?: string
    region_id?: string

    description?: string

    planned_risks?: IPlannedRisk[]
    stages?: IStage[]

    asset_variety_name?: string
    asset_variety_full_name?: string
    location_name?: string
    backend_id?: string
    action?: string
}

export interface IPlan {
    id?: string
    account_id?: string
    name?: string
    action?: string
    description?: string
    created_by?: string
    last_ran?: Date | string
    updated_at?: Date | string
    updated_by?: string
    selectedLocationsAssetVarieties?: Record<string, IBooleanDictionary>
    strategies?: IStrategy[]
}

export interface IStageLeft {
    stage_id: string
    left: number
}

export interface IDashboardLocation {
    location_id?: string
    asset?: string
    active?: boolean
    // TODO @sici fix dashboard variety interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    varieties?: any[]
}

export interface IDashboardFilters {
    prop: string
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    value: any
}

export interface IScheduler {
    id?: string
    created_at?: Date | string
    updated_at?: Date | string
    dashboard_id?: string
    account_id?: string
    user_id?: string
    created_by_email?: string
    run_daily?: boolean
    timezone?: string
    month_day?: number
    week_day?: number
    day_hour?: number
    body?: string
    subject?: string
    emails?: string[]
    filters?: IDashboardFilters[]
    filter_groups?: IDashboardFilters[][]
}

export interface IDashboard {
    account_id?: string
    created_by_email?: string
    updated_by_email?: string
    title?: string
    description?: string
    dtype?: string
    locations?: IDashboardLocation[]
    id?: string
    created_at?: Date | string
    updated_at?: Date | string
    filters?: string
    widgets?: string
    report_schedules?: IScheduler[]
}

export interface ISchedulerAccount extends IScheduler {
    dashboard?: IDashboard
}

export interface IScheduleFilters {
    scope?: string
    directionality?: string
    variables?: IBooleanDictionary
    locId?: string
    regions?: IBooleanDictionary
}

export interface IEmailDashboard {
    dashboard_url?: string
    dashboard_urls?: string[]
    refresh_token?: string
    account_id?: string
    user_id?: string
    dashboard_name?: string
    dashboard_title?: string
    emails?: string[]
    subject?: string
    body?: string
}

export interface IVariable {
    title: string
    label: string
    name: string
    value: string
    chartConfig: {
        color: string
        units_metric?: string
        units_imperial?: string
        zeroBasis?: boolean
    }
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

export interface IYieldUnitConversion {
    unit: string
    conversion: number
}
export interface IAssetModel {
    id: string
    created_at?: string
    updated_at?: string
    region_id: string
    asset_id: string
    granularity?: string
    status?: string
    seasonal_status?: string
    climate_status?: string
    newest_seasonal_date?: string
    oldest_seasonal_date?: string
    unit_conversion?: IYieldUnitConversion
}

type Scenario = "usual" | "worst" | "best"
export interface IAnalogsSearchPayload {
    lat: number
    lon: number
    scenario?: Scenario
    reference_decades?: number[]
    discovery_decades?: number[]
    regions?: string[]
    months?: number[]
    variables?: string[]
    exclusionRadius?: string
}

export interface IAnalog {
    lat: number
    lon: number
    decades: string
    months: string
    value: number
    variableIndexes: Record<string, number>
    refLat: number
    refLon: number
}

export interface IMaxImpactSettings {
    limit: number
    type: string
}
export interface ISuggestedStageWithRisks {
    id: string
    created_at?: string
    days_from_start: number
    duration: number
    initial_date: string
    name: string
    stage_risk_profiles: {
        id: string
        risk_profile: IRiskProfile
        risk_profile_id: string
        stage_id: string
    }[]
    updated_at: string
    variety_id: string
}

export type TimelineAction = "ADDED" | "EDITED" // VALIDATE here?

export const TIMELINE_EDITION_ACTIONS: Record<TimelineAction, TimelineAction> =
    {
        ADDED: "ADDED",
        EDITED: "EDITED",
    }

export interface ITimelineRiskProfileStage {
    id: string
    row_id: string
    risk_profile_id: string
    risk_profile: IRiskProfile
    action?: TimelineAction
}

export interface ITimelineStage {
    id: string
    row_id: string
    name: string
    color: string
    start_date: Date
    duration: number
    riskProfileStages: ITimelineRiskProfileStage[]
    action?: TimelineAction
    wrappingCount?: number
    deleteStage?: () => void
    isOverflowing?: boolean
}

export interface ITimelineRow {
    id: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any // to show
    stages: ITimelineStage[]
    gddCoords?: ICoordinates // To show the GDD row
    action?: TimelineAction
}

export interface TimelineStageInput {
    id?: string
    variety_id: string
    name: string
    color: string
    days_from_start: number
    duration: number
    risk_profile_ids: string[]
}

export interface IImpactDriver {
    name: string
    mean_impact: number
}
export interface IGenericDistribution {
    "0.05": number
    "0.25": number
    "0.50": number
    "0.75": number
    "0.95": number
}
export interface IYieldStat {
    model_id?: string
    deviation_distribution?: IGenericDistribution
    outlook_distribution?: IGenericDistribution
    deviation_mean: number
    historical: {
        yield_terciles: number[]
    }
    impact_drivers: IImpactDriver[]
    outlook_mean: number
    tercile_probabilities: {
        "0": number
        "0.33": number
        "0.66": number
    }
}
export interface IDecadalStat extends IYieldStat {
    decade: number
}
export interface ISeasonalStat extends IYieldStat {
    scenario: string
    init_time: string // date string
}
export const isDecadalStat = (
    stat: IDecadalStat | ISeasonalStat
): stat is IDecadalStat => (stat as any).decade !== undefined

export interface IYieldOutlookResponse<
    IStat extends IDecadalStat | ISeasonalStat
> {
    territory: {
        id: string
        name: string
    }
    stats: {
        results: IStat[]
    }
}

export interface IYieldOutlookLocationModel {
    id: string
    model_id: string
    model?: IAssetModel
    geo_id: string
    location_id: string
    latitude: number
    longitude: number
    seasonal_status: "active" | "inactive"
}

export interface IYieldOutlookLocationGQL extends IYieldOutlookLocationModel {
    location: IInsightsLocation | null
    newest_seasonal_date: string
    oldest_seasonal_date: string
}

// THIRD-PARTY LIBRARY
// use-query-params
type UrlUpdateType = "replace" | "replaceIn" | "push" | "pushIn"
type NewValueType<D> = D | ((latestValue: D) => D)
export type SetQueryParam<T> = (
    newValue: NewValueType<T>,
    updateType?: UrlUpdateType
) => void


interface ISimpleData {
    name: string,
    id: string
}

interface ISimpleVarietyData extends ISimpleData {
    asset: ISimpleData
}

interface ISimpleStageData extends ISimpleData {
    variety: ISimpleVarietyData
}

export interface IRiskProfileAssetMetadata {
    id: string,
    stage: ISimpleStageData
}