import { DefaultParamType, TFnType } from "@tolgee/react"

import { CalendarIcon, BoxIcon } from "../../../../climateui/icons"
import { RiskIconHandler, StackedRiskIcon } from "../../../../climateui/icons/riskIcons"
import { IHazardProfile, IRiskProfileAssetMetadata } from "../../../../types"
import { createGraphQLProxy } from "../../../../utils/networking"

import {
    formatDateRange,
    formatLastOccurrence,
    formatter,
    getCategoryStyle,
    getMagnitudeDescription,
    TimeResolutionValues,
} from "../utils"
import { alertCategoriesEnum } from "./mapUtils"
import { IPanelContent } from "./SidePanel"
import { ReactNode } from "react"
import { IUnitConversion } from "../../../../providers/UnitConversionProvider"
import DefaultRiskIcon from "../../../../climateui/icons/riskIcons/DefaultRiskIcon"


function RiskProfileDetailCard({
    riskProfileInfo,
    alertCategory,
    riskProfileSettingsAssetData,
    riskSettingAssetDataLoading,
    t,
    convertUnits,
    getUnit
  }: {
    readonly riskProfileInfo: IPanelContent
    readonly alertCategory: alertCategoriesEnum
    readonly convertUnits: IUnitConversion['convertUnits']
    readonly getUnit: IUnitConversion['getUnit']
    readonly riskProfileSettingsAssetData?:  Record<string, IRiskProfileAssetMetadata>
    readonly riskSettingAssetDataLoading?: boolean
    readonly scope?: number
    t: TFnType<DefaultParamType, string>
  }) {
    const riskProfileHazardProfiles = createGraphQLProxy(
        riskProfileInfo?.riskProfile
    ).hazard_profiles as IHazardProfile[]

    const isStacked = riskProfileHazardProfiles && riskProfileHazardProfiles.length > 1

    const orderHazardProfiles = () =>{
        const startProfile = riskProfileHazardProfiles.find(
            (profile) => profile.logical_op?.toUpperCase() === "START"
        )
        const andProfiles = riskProfileHazardProfiles.filter(
            (profile) => profile.logical_op?.toUpperCase() === "AND"
        )

        return startProfile ? [startProfile, ...andProfiles] : andProfiles
    }

    function buildHazardProfileDefinition(hazardProfile: IHazardProfile, threshold: number) {

        if(!hazardProfile) return "";

        const variable = hazardProfile.hazard_variable.readable_name
        const conditional = hazardProfile.conditional
        const unit = getUnit(hazardProfile.hazard_variable)
        const convertedThreshold = convertUnits(
            threshold,
            hazardProfile.hazard_variable,
            "convertUnits"
        )
        return `${variable} ${conditional} ${convertedThreshold.toFixed(2).replace(".00", "")} ${unit}`
    }
    
    function buildRiskProfileDate(){
        const { alert } = riskProfileInfo || {}
        if (!alert) return null

        const { time_resolution, start_date, end_date } = alert

        const validTimeResolution: TimeResolutionValues = 
            ["daily", "weekly", "monthly"].includes(time_resolution) ? 
                (time_resolution as TimeResolutionValues)
                : "daily"
                
        const dateRange = formatDateRange(validTimeResolution, start_date, end_date);

        return (
            <div className="flex items-end">
              {!["daily"].includes(validTimeResolution) && (
                <div className="body-sm text-gray-90 mr-1">
                  {dateRange.includes(" - ")
                    ? t("between", "Between")
                    : t("during", "During")}
                </div>
              )}
              <div className="body-md text-gray-90">{dateRange}</div>
            </div>
          );
    }

    function buildRiskProfileProbability() {
        const probabilityPercentage = formatter.format(
            riskProfileInfo.alert.avg_risk_value
        )
        return  ` ${probabilityPercentage} ${t("probability", "Probability")}` 
    }
    
    function buildRiskProfileExtremeLabel() {
        if(isStacked){
            return `${t("extreme_risk_values", "Extreme values: ")}`
        }

        const hazardProfile = riskProfileHazardProfiles[0]
        const conditional = hazardProfile?.conditional
        let extremeLabel = t("min_risk_value_label", "Minimum value:")
        if (conditional === ">"){
            extremeLabel = t("max_risk_value_label", "Maximum value:")
        }
        return extremeLabel
    }

    function buildRiskProfileExtremeValues(){
        const orderedHazardProfiles = orderHazardProfiles()
        const expectedMagnitudeValues =
            riskProfileInfo.alert.expected_magnitude_list?.map((magnitude, index) =>
                convertUnits(
                    magnitude,
                    orderedHazardProfiles[index]?.hazard_variable,
                    "convertUnits"
                )
            ) || []
        const magnitudeDescription = expectedMagnitudeValues
            ? getMagnitudeDescription(
                  expectedMagnitudeValues,
                  orderedHazardProfiles,
                  t,
                  getUnit
              )
            : ""
        return magnitudeDescription
    }

    function buildRiskProfileFrequency(){
        return t("risk_profile_frequency", "Happens once every {frequency} years", { frequency: riskProfileInfo.alert.frequency })
    }

    function buildRiskProfileAssetData(){
        if(riskSettingAssetDataLoading) return "Loading..."
        if(!riskProfileSettingsAssetData) return "No asset associated"
        
        const data = riskProfileSettingsAssetData[riskProfileInfo.riskSettingId]
        if(!data) return "No asset associated"

        const stageName = data.stage?.name ?? 'No asset associated';
        const assetName = data.stage?.variety?.asset?.name ?? '';
        const varietyName = data.stage?.variety?.name ?? '';

        // Construct the result string
        return `${stageName} • ${assetName} (${varietyName})`;
    }

    function getAlertInfo(attribute: string){
        switch (attribute) {
            case "probability":
                return buildRiskProfileProbability()
            case "definition":
                return buildHazardProfileDefinition(riskProfileHazardProfiles[0], riskProfileInfo.alert.trigger_threshold_list[0])
            case "date":
                return buildRiskProfileDate()
            case "lastOccurrence":
                return formatLastOccurrence(t, riskProfileInfo.alert.last_occurrence)
            case "extremeLabel":
                return buildRiskProfileExtremeLabel()
            case "extremeValues":
                return buildRiskProfileExtremeValues()
            case "frequency":
                return buildRiskProfileFrequency()
            case "assetData":
               return buildRiskProfileAssetData()
            default: return ""
        }

    }

    const IconWithText = ({ Icon, fill, text }: { Icon: React.ElementType; fill: string; text: ReactNode}) => (
        <div className="flex flex-row gap-x-2 w-full mt-3">
            <div className="w-10 shrink-0 rounded flex justify-end">
                <div className="flex justify-center items-start w-[18px] h-[18px] min-w-[18px] min-h-[18px]">
                    <Icon fill={fill} />
                </div>
            </div>
            <div className="flex w-full flex-col">
                <div className="body-sm text-gray-90">{text}</div>
            </div>
        </div>
    )

    return (
        <div className="flex flex-wrap gap-x-3 my-4 mx-2">
             <div className="flex flex-row gap-x-2">
                <div
                    className={`w-10 h-10 p-2 shrink-0 rounded flex justify-center items-center 
                        ${getCategoryStyle(alertCategory)}`}
                >
                    <RiskIconHandler hazardProfiles={riskProfileHazardProfiles} />
                </div>

                <div className="flex flex-wrap">
                    <p className="title-sm text-gray-90">
                        {riskProfileInfo?.riskProfile?.name}
                    </p>
                    <div className="w-full body-sm text-gray-60 mb-1">
                        {isStacked
                        ? t('stackedRisk', 'Stacked Risk')
                        : getAlertInfo('definition')}
                    </div>
                </div>
            </div>
            <IconWithText
                Icon={CalendarIcon}
                fill="#666D74"
                text={
                <>
                    <div className="flex w-full justify-between">
                        <div className="flex items-end">{getAlertInfo('date')}</div>
                            <div className="body-md text-gray-60">
                                {getAlertInfo('probability')}
                            </div>
                        </div>
                    <div className="body-sm text-gray-60">
                    {getAlertInfo('lastOccurrence')}
                    </div>
                </>
                }
            />

            {isStacked && (
                <IconWithText
                Icon={StackedRiskIcon}
                fill="#666D74"
                text={
                    <>
                        <div className="body-md text-gray-90 font-medium">
                            {t('stack', 'Stack')}:
                        </div>
                        {orderHazardProfiles().map((hazardProfile, index) => (
                            <div className="body-md text-gray-90" key={index}>
                            {buildHazardProfileDefinition(
                                hazardProfile,
                                riskProfileInfo.alert.trigger_threshold_list[index] || 0
                            )}
                            </div>
                        ))}
                    </>
                }
                />
            )}
            <IconWithText
                Icon={BoxIcon}
                fill="#666D74"
                text={riskSettingAssetDataLoading ? t('loading', 'Loading...') : getAlertInfo('assetData')}
            />
            <IconWithText
                Icon={DefaultRiskIcon}
                fill="#BF1728"
                text={
                    <>
                        <div className="flex w-full flex-col">
                            <div className="flex">
                                <div className="body-md text-gray-90 font-medium">
                                {getAlertInfo('extremeLabel')}
                                </div>
                                <div className="body-md text-gray-90 ml-1">
                                {getAlertInfo('extremeValues')}
                                </div>
                            </div>
                            <div className="body-sm text-gray-60">
                                {getAlertInfo('frequency')}
                            </div>
                        </div>
                    </>
                }
            />
        </div>
  )
}

export default RiskProfileDetailCard
