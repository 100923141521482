import { memo, useEffect, useMemo } from "react"
import AlertMapWidget from "../../../../climateui/components/Widgets/SeasonalWidgets/AlertMapWidget"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { MultiSelectFilter } from "../../../../climateui/components"
import DASHBOARD_VARIABLES from "../dashboardVariables"
import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import {
    useQueryParam,
    withDefault,
    StringParam,
    ArrayParam,
} from "use-query-params"

import { useAuth } from "../../../../providers"
import { FilterIcon } from "../../../../climateui/icons"

// Widgets
// import VariableWithMostAndLeastAlerts from "../components/widgetLibrary/VariableWithMostAndLeastAlerts"
// import LocationWithMostAndLeastAlerts from "../components/widgetLibrary/LocationWithMostAndLeastAlerts"
import DirectionalTableWidget from "../../../../climateui/components/Widgets/Table/DirectionalTableWidget"
import { getQueryParam } from "../../../../utils/queryParams"
import { FeatureFlag } from "../../../../components"
import { IDashboardLocation } from "../../../../types"
import useDashboardRegion from "../../../../hooks/Dashboards/useDashboardRegion"
import useDashboardVarieties from "../../../../hooks/Dashboards/useDashboardVarieties"
import useDashboardRisks from "../../../../hooks/Dashboards/useDashboardRisks"
import GenericDashboardFilters from "../components/Filters/GenericDashboardFilters"
import { useNavigate, useParams } from "react-router-dom"
import CalendarWidgetWrapper from "../components/widgetLibrary/CalendarWidgetWrapper"
import DashNameandDescription from "./DashNameandDescription"
import { useDashboard } from "../../../../providers/DashboardProvider"
import CategorySelector from "../../Alerts/components/Filters/WarningWatchSelector"
import { DateTime } from "luxon"
import { NOON } from "../../../../utils/dates"
import AlertsTableWidgetWrapper from "../components/widgetLibrary/AlertsTableWidgetWrapper"

import useWidgetLifecycleTracker from "../../../../climateui/hooks/useWidgetLifecycleTracker"
import DoneLoading from "../components/DoneLoading"

import { useFlagValue } from "../../../../hooks"
import {
    DEFAULT_DASHBOARD_PROMISE_HARVESTER_DELAY,
    formatDashboardLabel,
} from "../utils"
import {
    getTimeWindowByGranularity,
    TimeResolutionValues,
} from "../../Alerts/utils"
import { TToggleOptions } from "../../../../climateui/components/Inputs/ToggleSelector"

const variables = DASHBOARD_VARIABLES

export interface IRegionalDashboardProps {
    customLocations?: IDashboardLocation[]
}

function RegionalDashboard(props: IRegionalDashboardProps) {
    const { t } = useTranslate()
    const navigate = useNavigate()
    // Custom locations come from GenericDashboardView
    // They get passed as props to Location and Regional Dashboard
    const { customLocations } = props
    const { dashboardId } = useParams()
    const { workingDashboard } = useDashboard()

    const { user } = useAuth()

    // >>>>>>>>>
    // Locations & Regions
    // >>>>>>>>>
    const {
        loadingLocations,
        customRegionsDict,
        regionsLocationsDict,
        customLocationsDict,
        allLocationsDict,
    } = useDashboardRegion(customLocations, dashboardId === "portfolio")

    const RegionParam = withDefault(ArrayParam, [])
    const [selectedRegions, setSelectedRegions] = useQueryParam(
        "region",
        RegionParam
    )

    const regionLocations = useMemo(() => {
        const regionLocations: string[] = []
        let regionNames: string[] = []
        if (
            selectedRegions &&
            selectedRegions.length > 0 &&
            Object.keys(regionsLocationsDict).length > 0
        ) {
            regionNames = selectedRegions as string[]
        } else {
            regionNames = Object.keys(regionsLocationsDict)
        }
        regionNames.forEach((region) => {
            const res = regionsLocationsDict[region]
                .map((loc) => loc.id ?? "")
                .filter((id) => id)
            regionLocations.push(...res)
        })
        return regionLocations
    }, [
        customLocations,
        selectedRegions,
        regionsLocationsDict,
        loadingLocations,
    ])

    // >>>>>>>>>
    // Scope
    // >>>>>>>>>

    const scopesDict: Record<string, string> = {
        daily: t("daily"),
        weekly: t("weekly"),
        monthly: t("monthly"),
    }
    const ScopeParam = withDefault(StringParam, "daily")
    const [selectedScope, setSelectedScope] = useQueryParam("scope", ScopeParam)

    const categoryParam = withDefault(StringParam, "warning")
    const [selectedCategory, setSelectedCategory] = useQueryParam(
        "category",
        categoryParam
    )

    // >>>>>>>>>
    // Assets and Varieties filter
    // >>>>>>>>>

    const { varietiesDict } = useDashboardVarieties(
        customLocations,
        customLocationsDict,
        regionLocations,
        allLocationsDict,
        dashboardId === "portfolio"
    )

    const VarietiesArrayParam = withDefault(ArrayParam, [])
    const [selectedVarieties, setSelectedVarieties] = useQueryParam(
        "varieties",
        VarietiesArrayParam
    )

    useEffect(() => {
        setSelectedVarieties(Object.keys(varietiesDict))
    }, [selectedRegions.length])

    // >>>>>>>>>
    // Variables
    // >>>>>>>>>

    // Toggle options
    // Process the variables dictionary
    const filteredVariablesDict: TToggleOptions = {}

    // convert variables dictionary into variables options dict
    Object.keys(variables).forEach((key) => {
        // Remove precipitation sum
        if (key === "precipitation_sum") return
        filteredVariablesDict[key] = {
            key,
            value: variables[key].name,
            disabled: key === "max_wind_speed" && selectedScope !== "daily",
        }
    })

    // State
    const VariableArrayParam = withDefault(ArrayParam, [] as string[])
    const [selectedVariables, setSelectedVariables] = useQueryParam(
        "variables",
        VariableArrayParam
    )

    useEffect(() => {
        if (selectedVariables.length === 0) {
            setSelectedVariables(["temp_max", "temp_min", "precipitation"])
        }
    }, [dashboardId])

    // >>>>>>>>>
    // Risk profiles & Risk settings
    // >>>>>>>>>

    const {
        // alertSettingsByVariable,
        loadingRisks,
        loadingAlertSettings,
        alertSettingsIds,
        varietiesIds,
    } = useDashboardRisks(
        regionLocations,
        selectedVarieties,
        selectedVariables,
        variables,
        varietiesDict,
        dashboardId === "portfolio"
    )

    // >>>>>>>>>
    // Dashboard filters
    // >>>>>>>>>

    // Filters to apply to all widgets
    const dashboardFilters = useMemo(() => {
        const [startDate, endDate] = getTimeWindowByGranularity(
            selectedScope as TimeResolutionValues
        )

        return [
            {
                propName: "hazard_variables",
                value: [
                    ...selectedVariables,
                    selectedVariables.includes("precipitation" as never)
                        ? "precipitation_sum"
                        : "",
                ],
                loading: false,
            },
            {
                propName: "location_ids",
                value: regionLocations,
                loading: loadingLocations,
            },
            {
                propName: "variety_ids",
                value: varietiesIds,
                loading: loadingRisks || loadingAlertSettings,
            },
            {
                propName: "risk_settings_ids",
                value: alertSettingsIds,
                loading: loadingRisks || loadingAlertSettings,
            },
            {
                propName: "start_date_lte",
                value: endDate.toUTC().toISODate(),
                loading: false,
            },
            {
                propName: "end_date_gte",
                value: startDate.toUTC().toISODate(),
                loading: false,
            },
            {
                propName: "unit_system",
                value: user?.unit_type ? "metric" : "imperial",
                loading: false,
            },
            {
                propName: "category",
                value: selectedCategory,
                loading: false,
            },
        ]
    }, [
        selectedVariables,
        selectedVarieties,
        selectedScope,
        !loadingLocations &&
            !!selectedRegions &&
            selectedRegions.length > 0 &&
            selectedRegions,
        regionLocations,
        alertSettingsIds,
        loadingRisks,
        loadingAlertSettings,
        user?.unit_type,
    ])

    // >>>>>>>>>
    // Dashboard directional filters
    // >>>>>>>>>

    const directionalFilters = useMemo(() => {
        // set default variables if none is selected
        let variablesToFetch =
            selectedVariables.length > 0
                ? selectedVariables
                : Object.keys(filteredVariablesDict)

        // remove precipitation_sum from selected
        variablesToFetch = variablesToFetch.filter(
            (v) => v !== "precipitation_sum"
        )

        return [
            {
                propName: "location_ids",
                value: regionLocations,
                loading: loadingLocations,
            },
            {
                propName: "config",
                value: variablesToFetch.map((v) => ({
                    variable: v,
                    visualization: "directionality",
                    dataset: "forecast",
                })),
                loading: false,
            },
            {
                propName: "granularity",
                value: "monthly",
                loading: false,
            },
        ]
    }, [
        selectedVariables,
        !loadingLocations &&
            !!selectedRegions &&
            selectedRegions &&
            selectedRegions.length > 0,
        regionLocations,
        user?.unit_type,
    ])

    const paginationParam = getQueryParam("pagination")
    const isPaginated = !paginationParam ? true : paginationParam === "true"

    // >>>>>>>>>
    // Widget lifecycle tracker
    // >>>>>>>>>

    const reportFlagDelay =
        (useFlagValue("feature_alert_dashboards_promise_harvester_delay") as
            | number
            | undefined) ?? DEFAULT_DASHBOARD_PROMISE_HARVESTER_DELAY

    const { settled, register, load } = useWidgetLifecycleTracker({
        delay: reportFlagDelay,
    })

    return (
        <div className="w-full h-full">
            {/* Dashboard print label (required) */}
            <div
                className="hidden"
                id="current-page-label">
                {formatDashboardLabel(
                    workingDashboard?.title ?? "",
                    selectedScope
                )}
            </div>
            {/* PDF REPORTS DIV */}
            {settled && <DoneLoading />}
            <div className="sticky top-0 z-30">
                <DashNameandDescription workingDashboard={workingDashboard} />
                <div className="pb-[14px] flex flex-wrap gap-2 align-baseline -mt-5 bg-gray-1.5">
                    <MultiSelectFilter
                        filterName={t("country")}
                        canSearch={true}
                        searchPlaceholder={t("searchCountry", "Search country")}
                        icon={<FilterIcon />}
                        placeholder={t("country", "Country")}
                        selected={[...selectedRegions].reduce((prev, curr) => {
                            if (curr) prev[curr] = true
                            return prev
                        }, {} as { [key: string]: boolean })}
                        setSelected={(reg) =>
                            setSelectedRegions(Object.keys(reg))
                        }
                        leftRightClass="left-0"
                        options={
                            {
                                ...customRegionsDict,
                            } as { [key: string]: string }
                        }
                    />
                    {/* THIS COMPONENT WILL BE DEPRECATED AFTER NEW DASHBOARD FILTER IMPLEMENTATION.
                    DID A FEW CHANGES JUST SO THAT IT WOULDN'T CRASH WITH THE NEW RADIO SELECTOR COMPONENT*/}
                    <GenericDashboardFilters
                        // asset / variety filter
                        setSelectedVarieties={setSelectedVarieties}
                        varietiesDict={varietiesDict}
                        selectedVarieties={selectedVarieties as string[]}
                        // variable filter
                        variablesDict={filteredVariablesDict}
                        selectedVariables={selectedVariables as string[]}
                        updateSelectedVariables={(currElement: string) => {
                            if (selectedVariables.includes(currElement)) {
                                const updateElements = selectedVariables.filter(
                                    (v) => v != currElement
                                )
                                setSelectedVariables(updateElements)
                                return
                            }

                            setSelectedVariables([
                                ...selectedVariables,
                                currElement,
                            ])
                        }}
                        // scope filter
                        selectedScope={selectedScope}
                        setSelectedScope={(scope: string) => {
                            // if scope != daily, remove max windspeed from selected variables
                            if (["monthly", "weekly"].includes(scope))
                                setSelectedVariables(
                                    selectedVariables.filter(
                                        (v) => v !== "max_wind_speed"
                                    )
                                )

                            setSelectedScope(scope)
                        }}
                        scopesDict={scopesDict}
                    />
                    <CategorySelector
                        selectedCategory={selectedCategory}
                        handleSelectCategory={setSelectedCategory}
                    />
                </div>
            </div>
            <div className="flex gap-[14px] mb-[14px]">
                <div className="w-full h-[480px]">
                    <WidgetWrapper
                        id="alert_map_widget"
                        onMount={register}
                        onLoad={load}
                        component={AlertMapWidget}
                        query={gql`
                            query (
                                $location_ids: [String]
                                $risk_settings_ids: [String]
                                $start_date_lte: String
                                $end_date_gte: String
                                $category: String
                            ) {
                                locations(
                                    filter: { location_ids: $location_ids }
                                ) {
                                    results {
                                        id
                                        latitude
                                        longitude
                                        alerts(
                                            filter: {
                                                risk_settings_ids: $risk_settings_ids
                                                start_date_lte: $start_date_lte
                                                end_date_gte: $end_date_gte
                                                category: $category
                                            }
                                        ) {
                                            results {
                                                id
                                                processing_run {
                                                    risk_id
                                                }
                                                category
                                                avg_risk_value
                                                frequency
                                                start_date
                                                end_date
                                                time_resolution
                                            }
                                        }
                                    }
                                }
                            }
                        `}
                        selectors={{
                            $coords: "locations.results",
                            $pinValues:
                                "locations.results[].alerts[].info.count",
                            animate: isPaginated,
                            hasControls: isPaginated,
                            hasGeocoder: false,
                            navigate: navigate,
                            pinURL: "/seasonal/dashboards/location?locId=",
                            pinImages: {
                                warningState: "map-alert-pin.png",
                                watchState: "map_alert_watch_pin.png",
                                selectedWarningState:
                                    "map-alert-pin-selected.png",
                                selectedWatchState:
                                    "map-watch-alert-pin-selected.png",
                            },
                        }}
                        filters={dashboardFilters}
                    />
                </div>
                {/* <div className="w-1/4 grid gap-[14px] grid-cols-1 grid-rows-4">
                    <div>
                        <LocationWithMostAndLeastAlerts
                            id="location_with_most_alerts_widget"
                            onMount={register}
                            onLoad={load}
                            selectors={{
                                title: t("locationWithMostAlertsWidgetTitle"),
                                $value: "locationAggregatedByAlert.location.name",
                                $description:
                                    "locationAggregatedByAlert.location.alerts.info.count",
                            }}
                            dashboardFilters={dashboardFilters}
                            hwCount={true}
                        />
                    </div>
                    <div>
                        <LocationWithMostAndLeastAlerts
                            id="location_with_fewest_alerts_widget"
                            onMount={register}
                            onLoad={load}
                            selectors={{
                                title: t("locationWithFewestAlertsWidgetTitle"),
                                $value: "locationAggregatedByAlert.location.name",
                                $description:
                                    "locationAggregatedByAlert.location.alerts.info.count",
                            }}
                            dashboardFilters={dashboardFilters}
                            hwCount={false}
                        />
                    </div>
                    <div>
                        <VariableWithMostAndLeastAlerts
                            id="variable_with_most_alerts_widget"
                            onMount={register}
                            onLoad={load}
                            hwCount={true}
                            dashboardFilters={dashboardFilters}
                            selectors={{
                                title: t("variableWithMostAlertsWidgetTitle"),
                            }}
                        />
                    </div>
                    <div>
                        <VariableWithMostAndLeastAlerts
                            id="variable_with_fewest_alerts_widget"
                            onMount={register}
                            onLoad={load}
                            hwCount={false}
                            dashboardFilters={dashboardFilters}
                            selectors={{
                                title: t("variableWithLessAlertsWidgetTitle"),
                            }}
                        />
                    </div>
                </div> */}
            </div>

            {/* NEW CALENDAR WIDGET */}
            <div className="mb-[14px]">
                <FeatureFlag flags={["feature_dashboard_calendar_widget"]}>
                    <CalendarWidgetWrapper
                        id="alert_calendar_widget"
                        onMount={register}
                        onLoad={load}
                        dashboardFilters={dashboardFilters}
                        selectors={{
                            title: t("alertsCalendarWidgetTitle"),
                            isPaginated,
                            columns: [],
                            $data: "alerts.results",
                        }}
                    />
                </FeatureFlag>
            </div>
            <div className="w-full mb-[14px]">
                <WidgetWrapper
                    id="directionality_table_widget"
                    onMount={register}
                    onLoad={load}
                    component={DirectionalTableWidget}
                    query={gql`
                        query (
                            $location_ids: [String]
                            $config: [WeatherConfig]!
                            $granularity: String
                        ) {
                            locations(filter: { location_ids: $location_ids }) {
                                results {
                                    id
                                    name
                                    weather(
                                        filter: {
                                            config: $config
                                            granularity: $granularity
                                        }
                                    ) {
                                        data
                                    }
                                }
                            }
                        }
                    `}
                    selectors={{
                        title: t("directionalForecast"),
                        $data: "locations.results[]",
                        errorMessage: t("errorLoadingData"),
                        reloadMessage: t("clickReload"),
                        noResultsMessage: t("noResultCurrentFilters"),
                        tryAnotherFilterMessage: t("tryAnotherFilter"),
                        isPaginated,
                        columns: [
                            {
                                propName: "location",
                                header: t("location"),
                                type: "link",
                                selector: {
                                    text: "{{ name }}",
                                    href: "/seasonal/dashboards/location?locId={{id}}",
                                },
                            },
                        ],
                        footerItems: [
                            {
                                label: t("aboveHistorical", "Above Normal"),
                                image: "/images/BelowIcon.svg",
                                className: "w-[20px] h-[20px] rotate-180",
                            },
                            {
                                label: t("withinHistorical", "Within Normal"),
                                image: "/images/WithinIcon.svg",
                                className: "w-[20px] h-[20px]",
                            },
                            {
                                label: t("belowHistorical", "Below Normal"),
                                image: "/images/BelowIcon.svg",
                                className: "w-[20px] h-[20px]",
                            },
                        ],
                    }}
                    filters={directionalFilters}
                />
            </div>
            <div className="w-full mb-[14px]">
                <AlertsTableWidgetWrapper
                    id="alert_summary_table_widget"
                    onMount={register}
                    onLoad={load}
                    dashboardFilters={dashboardFilters}
                    selectors={{
                        title: t("triggeredAlertsWidgetTitle"),
                        isPaginated,
                    }}
                />
            </div>
        </div>
    )
}

export default memo(RegionalDashboard)
